<template>
  <div class="call-recording__record">
    <div>
      <audio
        :id="playerId"
        ref="player"
        style="display:none">
        <source
          :src="url"
          type="audio/mpeg">
      </audio>
    </div>

    <div class="call-recording__record-inner">
      <div
        v-show="audioLoaded"
        class="call-recording__record-svg">
        <font-awesome-icon
          v-show="!isPlaying"
          class="success"
          :icon="dataIconPlay"
          @click="toggleAudio()" />
        <font-awesome-icon
          v-show="isPlaying"
          :icon="dataIconPause"
          @click="toggleAudio()" />
      </div>

      <div class="call-recording__record-timeline">
        <input
          v-show="audioLoaded"
          v-model="playbackTime"
          type="range"
          min="0"
          :max="audioDuration"
          name="position">
        <div
          v-show="!audioLoaded"
          style="color: #20a052; font-size: 14px;">
          Loading please wait...
        </div>
        <div
          v-show="audioLoaded">
          <span
            style="color: #222; font-size: 14px;"
            v-html="elapsedTime()"> 00:00 </span>
        </div>
        <div class="call-recording__record-check">
          <font-awesome-icon
            v-show="audioLoaded"
            :class="isListened && 'success'"
            :icon="dataIconCheck" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faCheckCircle, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallRecording',
  props: {
    url: {
      type: String,
      default: null
    },
    playerId: {
      type: Number,
      default: null
    },
    isListened: {
      type: Boolean,
      default: false
    }
  },
  /**
   * playbackTime = local var that syncs to audio.currentTime
   * audioDuration = duration of audio file in seconds
   * isPlaying = boolean (true if audio is playing)
   *
   **/
  data () {
    return {
      dataIconPlay: faPlay,
      dataIconPause: faPause,
      dataIconCheck: faCheckCircle,
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      dataTimout: null,
      dataSecondsCount: 0
    };
  },
  mounted () {
    // nextTick code will run only after the entire view has been rendered
    this.$nextTick(function () {
      const audio = this.$refs.player;
      // Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
      audio.addEventListener(
        'loadedmetadata',
        function (e) {
          this.initSlider();
        }.bind(this)
      );
      // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
      audio.addEventListener(
        'canplay',
        function (e) {
          this.audioLoaded = true;
        }.bind(this)
      );
      // Wait for audio to begin play, then start playback listener function
      this.$watch('isPlaying', function () {
        if (this.isPlaying) {
          const audio = this.$refs.player;
          this.initSlider();
          // console.log("Audio playback started.");
          // prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true;
            // for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio.addEventListener('timeupdate', this.playbackListener);
          }
          this.$_checkTimoutSecondsStart();
        } else {
          this.$_checkTimoutSecondsStop();
        }
      });
      // Update current audio position when user drags progress slider
      this.$watch('playbackTime', function () {
        const diff = Math.abs(this.playbackTime - this.$refs.player.currentTime);

        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.$refs.player.currentTime = this.playbackTime;
        }
      });
    });
  },
  methods: {
    // Set the range slider max value equal to audio duration
    initSlider () {
      const audio = this.$refs.player;
      if (audio) {
        this.audioDuration = Math.round(audio.duration);
      }
    },
    // Convert audio current time from seconds to min:sec display
    convertTime (seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2);
      const minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(':');
    },
    // Display the audio time elapsed so far
    elapsedTime () {
      const audio = this.$refs.player;
      if (audio) {
        const seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },
    // Playback listener function runs every 100ms while audio is playing
    playbackListener (e) {
      const audio = this.$refs.player;
      // Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = audio.currentTime;

      // console.log("update: " + audio.currentTime);
      // Add listeners for audio pause and audio end events
      audio.addEventListener('ended', this.endListener);
      audio.addEventListener('pause', this.pauseListener);
    },
    // Function to run when audio is paused by user
    pauseListener () {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    // Function to run when audio play reaches the end of file
    endListener () {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    // Remove listeners after audio play stops
    cleanupListeners () {
      const audio = this.$refs.player;
      audio.removeEventListener('timeupdate', this.playbackListener);
      audio.removeEventListener('ended', this.endListener);
      audio.removeEventListener('pause', this.pauseListener);
      // console.log("All cleaned up!");
    },
    toggleAudio () {
      const audio = this.$refs.player;
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    $_checkTimoutSecondsStart () {
      if (this.isListened) {
        return;
      }
      this.dataTimout = setInterval(_ => {
        this.dataSecondsCount += 1;
      }, 999);
    },
    $_checkTimoutSecondsStop () {
      if (this.isListened) {
        return;
      }
      clearInterval(this.dataTimout);
      this.dataTimout = null;
      if (this.playbackTime >= (this.audioDuration - 2) && Math.round(this.audioDuration / 1.5) < this.dataSecondsCount) {
        this.$emit('record-success', this.playerId);
      }
    }
  }
};
</script>

<style scoped>
input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 0; /* iOS */
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
::-webkit-slider-runnable-track {
  background: #fff;
}
/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0; /* 1 */
  height: 40px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw #20a052;
  border: none; /* 2px solid #999; */
}
::-moz-range-track {
  height: 40px;
  background: #ddd;
}
::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 0; /* 20px; */
  border: none; /* 3px solid #999; */
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw #20a052;
  box-sizing: border-box;
}
::-ms-fill-lower {
  background: #20a052;
}
::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}
::-ms-ticks-after {
  display: none;
}
::-ms-ticks-before {
  display: none;
}
::-ms-track {
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}
::-ms-tooltip {
  display: none;
}
</style>

<style lang="sass" scoped>
  .call-recording
    &__record
      &-inner
        display: flex
      &-svg
        flex: 0 0 18px
        margin-right: 4px
      &-timeline
        display: flex
        input
          height: 4px
          border-radius: 2px
          background-color: #c7c7c7
          margin-right: 4px
      &-check
        margin-left: 4px
</style>
