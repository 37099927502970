<template>
  <div
    v-if="objEvent != null"
    class="call-recording">
    <h2>{{ objEvent.name }}</h2>
    <div
      v-for="item in computedRecords"
      :key="item.id"
      class="call-recording__item">
      <h3 style="display: flex; justify-content: space-between;">
        <span>{{ item.created_at | dateFilter }}</span>
        <span>
          <font-awesome-icon :icon="dataUserIcon" />
          {{ item.player_id }}
        </span>
      </h3>
      <call-recording
        :url="item.link"
        :is-listened="item.is_listened"
        :player-id="item.id"
        @record-success="onUpdateComplited" />
    </div>

    <div
      v-if="computedIsEmptyRecords"
      class="empty">
      <font-awesome-icon
        :icon="dataIconPhone" />
      <p>There are no records for this Operator</p>
    </div>
  </div>
</template>

<script>
import CallRecording from '@/components/desktop/recording/CallRecording';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import helper from '@/service/helper';
import { CALL_UPDATE } from '@/store/types/actions.types';

export default {
  name: 'CallCallList',
  components: { CallRecording },
  props: {
    objEvent: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataUserIcon: faUser,
      dataIconPhone: faPhone,
      dataListComplitedRecords: []
    };
  },
  computed: {
    computedRecords () {
      return helper.cloneDeep((this.objEvent.records || [])).map(item => {
        item.link = `${item.link}?access_token=${this.$store.getters['auth/token']}`;
        item.is_listened = this.dataListComplitedRecords.includes(item.id) || item.is_listened;
        return item;
      });
    },
    computedIsEmptyRecords () {
      return helper.isEmpty(this.computedRecords);
    }
  },
  methods: {
    async onUpdateComplited (callId) {
      try {
        await this.$store.dispatch(`call/${CALL_UPDATE}`, {
          params: {
            is_listened: true
          },
          callId
        });
        this.dataListComplitedRecords.push(callId);
      } catch (e) {

      }
    }
  }
};
</script>

<style lang="sass" scoped>
.call-recording
  position: sticky
  top: 12px
  z-index: 10
  padding: 16px 16px 24px
  border-radius: 8px
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12)
  background-color: #ffffff
  h2
    margin-bottom: 16px
    font-size: 16px
    color: #222222
  &__item
    margin-bottom: 8px
    padding: 8px
    border-radius: 8px
    background-color: #eeeeef
    h3
      font-size: 12px
      color: #222222
      margin-bottom: 8px

.empty
  color: rgba(34, 34, 34, 0.6)
  text-align: center
  padding: 8px
  svg
    width: 24px
    height: 24px
  p
    padding-top: 8px
    font-size: 12px
</style>
