export default {
  data () {
    return {
      dataMixinCallsFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Name',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th'
        },
        {
          label: 'Email',
          key: 'email',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Team',
          key: 'teams',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Calls',
          key: 'calls',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
